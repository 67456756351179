import { cities } from '../cities'
import { useGlobalStore } from 'store/global/store'

export const useFetchCities = () => {


  const fetchCities = async () => {


  
    // const { data } = await api.citiesGet()


    useGlobalStore.setState({
     cities
    })
  }

  return { fetchCities }
}
