
import { City } from 'api/gen'

export const cities: City[] = [
  {
    id: 130201,
    name: "Hanoi",
    state_id: 3810,
    state_code: "HN",
    state_name: "Hà Nội",
    country_id: 240,
    country_code: "VN",
    country_name: "Vietnam",
    latitude: "21.02450000",
    longitude: "105.84117000",
    wikiDataId: "Q1858",
  },
  {
    id: 106448,
    name: "Bangkok",
    state_id: 3554,
    state_code: "10",
    state_name: "Bangkok",
    country_id: 219,
    country_code: "TH",
    country_name: "Thailand",
    latitude: "13.75398000",
    longitude: "100.50144000",
    wikiDataId: "Q1861",
  },
  {
    id: 106474,
    name: "Chiang Mai",
    state_id: 3491,
    state_code: "50",
    state_name: "Chiang Mai",
    country_id: 219,
    country_code: "TH",
    country_name: "Thailand",
    latitude: "18.79038000",
    longitude: "98.98468000",
    wikiDataId: "Q52028",
  },
  {
    id: 55004,
    name: "Zagreb",
    state_id: 738,
    state_code: "21",
    state_name: "Zagreb",
    country_id: 55,
    country_code: "HR",
    country_name: "Croatia",
    latitude: "45.81444000",
    longitude: "15.97798000",
    wikiDataId: "Q1435",
  },
  {
    id: 37875,
    name: "Valsequillo de Gran Canaria",
    state_id: 1185,
    state_code: "GC",
    state_name: "Las Palmas",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    latitude: "27.98562000",
    longitude: "-15.49725000",
    wikiDataId: "Q914195",
  },
  {
    id: 153201,
    name: "Santa María de Guía de Gran Canaria",
    state_id: 1185,
    state_code: "GC",
    state_name: "Las Palmas",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    latitude: "0.00000000",
    longitude: "0.00000000",
    wikiDataId: null,
  },
  {
    id: 900000000,
    name: "Canggu",
    state_id: 1826,
    state_code: "BA",
    state_name: "Bali",
    country_id: 102,
    country_code: "ID",
    country_name: "Indonesia",
    latitude: "-8.6478",
    longitude: "115.1385",
    wikiDataId: "Q18970",
  },
  {
    id: 76422,
    name: "Penang",
    state_id: 1939,
    state_code: "07",
    state_name: "Penang",
    country_id: 132,
    country_code: "MY",
    country_name: "Malaysia",
    latitude: "5.47090000",
    longitude: "100.24529000",
    wikiDataId: "Q4873309",
  },
  {
    id: 89230,
    name: "Lagos",
    state_id: 2239,
    state_code: "08",
    state_name: "Faro",
    country_id: 177,
    country_code: "PT",
    country_name: "Portugal",
    latitude: "37.10202000",
    longitude: "-8.67422000",
    wikiDataId: "Q35710384",
  },
  {
    id: 67526,
    name: "Skopje",
    state_id: 681,
    state_code: "38",
    state_name: "Karpoš Municipality",
    country_id: 129,
    country_code: "MK",
    country_name: "North Macedonia",
    latitude: "41.99646000",
    longitude: "21.43141000",
    wikiDataId: "Q384",
  },
  {
    id: 64845,
    name: "Nairobi",
    state_id: 191,
    state_code: "30",
    state_name: "Nairobi City",
    country_id: 113,
    country_code: "KE",
    country_name: "Kenya",
    latitude: "-1.28333000",
    longitude: "36.81667000",
    wikiDataId: "Q3870",
  },
  {
    id: 54904,
    name: "Split",
    state_id: 725,
    state_code: "17",
    state_name: "Split-Dalmatia",
    country_id: 55,
    country_code: "HR",
    country_name: "Croatia",
    latitude: "43.50891000",
    longitude: "16.43915000",
    wikiDataId: "Q1277701",
  },
  // {
  //   id: 106102,
  //   name: "Timişoara",
  //   state_id: 4748,
  //   state_code: "TM",
  //   state_name: "Timiș County",
  //   country_id: 181,
  //   country_code: "RO",
  //   country_name: "Romania",
  //   latitude: "45.75372000",
  //   longitude: "21.22571000",
  //   wikiDataId: "Q16898582",
  // },
  {
    id: 108095,
    name: "İstanbul",
    state_id: 2170,
    state_code: "34",
    state_name: "İstanbul",
    country_id: 225,
    country_code: "TR",
    country_name: "Turkey",
    latitude: "41.06947000",
    longitude: "28.76983000",
    wikiDataId: "Q639240",
  },
  {
    id: 143381,
    name: "Ericeira",
    state_id: 4859,
    state_code: "09",
    state_name: "Guarda",
    country_id: 177,
    country_code: "PT",
    country_name: "Portugal",
    latitude: "38.96277778",
    longitude: "-9.41527778",
    wikiDataId: "Q1351868",
  },
  {
    id: 143446,
    name: "Lisbon",
    state_id: 4859,
    state_code: "09",
    state_name: "Guarda",
    country_id: 177,
    country_code: "PT",
    country_name: "Portugal",
    latitude: "38.71666667",
    longitude: "-9.16666667",
    wikiDataId: "Q597",
  },
  {
    id: 89424,
    name: "Portimão",
    state_id: 2239,
    state_code: "08",
    state_name: "Faro",
    country_id: 177,
    country_code: "PT",
    country_name: "Portugal",
    latitude: "37.17544000",
    longitude: "-8.58420000",
    wikiDataId: "Q543542",
  },
  // {
  //   id: 106535,
  //   name: 'Ko Pha Ngan',
  //   state_id: 3482,
  //   state_code: '84',
  //   state_name: 'Surat Thani',
  //   country_id: 219,
  //   country_code: 'TH',
  //   country_name: 'Thailand',
  //   latitude: '9.75778000',
  //   longitude: '100.02914000',
  //   wikiDataId: 'Q15839185',
  // },
  {
    id: 71756,
    name: "Mexico City",
    state_id: 3473,
    state_code: "CDMX",
    state_name: "Ciudad de México",
    country_id: 142,
    country_code: "MX",
    country_name: "Mexico",
    latitude: "19.419753",
    longitude: "-99.138752",
    wikiDataId: "Q20129023",
  },
  {
    id: 87550,
    name: "Krakow",
    state_id: 1635,
    state_code: "MA",
    state_name: "Lesser Poland Voivodeship",
    country_id: 176,
    country_code: "PL",
    country_name: "Poland",
    latitude: "50.14347000",
    longitude: "19.91409000",
    wikiDataId: "Q1164890",
  },
  {
    id: 32653,
    name: "Barcelona",
    state_id: 5102,
    state_code: "B",
    state_name: "Barcelona",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    latitude: "41.38879000",
    longitude: "2.15899000",
    wikiDataId: "Q1492",
  },
  {
    id: 52126,
    name: "Tbilisi",
    state_id: 899,
    state_code: "TB",
    state_name: "Tbilisi",
    country_id: 81,
    country_code: "GE",
    country_name: "Georgia",
    latitude: "41.69411000",
    longitude: "44.83368000",
    wikiDataId: "Q2474275",
  },
  {
    id: 55263,
    name: "Budapest",
    state_id: 1064,
    state_code: "BU",
    state_name: "Budapest",
    country_id: 99,
    country_code: "HU",
    country_name: "Hungary",
    latitude: "47.49835000",
    longitude: "19.04045000",
    wikiDataId: "Q1781",
  },
  {
    id: 704,
    name: "Buenos Aires",
    state_id: 3656,
    state_code: "B",
    state_name: "Buenos Aires",
    country_id: 11,
    country_code: "AR",
    country_name: "Argentina",
    latitude: "-34.61315000",
    longitude: "-58.37723000",
    wikiDataId: "Q1486",
  },
  {
    id: 82238,
    name: "Cebu City",
    state_id: 1344,
    state_code: "BAN",
    state_name: "Bataan",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    latitude: "10.31672000",
    longitude: "123.89071000",
    wikiDataId: "Q31539118",
  },
  {
    id: 108024,
    name: "Antalya",
    state_id: 2169,
    state_code: "07",
    state_name: "Antalya",
    country_id: 225,
    country_code: "TR",
    country_name: "Turkey",
    latitude: "37.06498000",
    longitude: "30.19565000",
    wikiDataId: "Q3010236",
  },
  {
    id: 131679,
    name: "Delhi",
    state_id: 4021,
    state_code: "DL",
    state_name: "Delhi",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: "28.65195000",
    longitude: "77.23149000",
    wikiDataId: "Q1353",
  },
  {
    id: 64500,
    name: "Tokyo",
    state_id: 823,
    state_code: "13",
    state_name: "Tokyo",
    country_id: 109,
    country_code: "JP",
    country_name: "Japan",
    latitude: "35.68950000",
    longitude: "139.69171000",
    wikiDataId: "Q207309",
  },
  {
    id: 65321,
    name: "Seoul",
    state_id: 3849,
    state_code: "11",
    state_name: "Seoul",
    country_id: 116,
    country_code: "KR",
    country_name: "South Korea",
    latitude: "37.49510000",
    longitude: "127.06278000",
    wikiDataId: "Q20398",
  },
  {
    id: 24053,
    name: "Berlin",
    state_id: 3010,
    state_code: "BE",
    state_name: "Berlin",
    country_id: 82,
    country_code: "DE",
    country_name: "Germany",
    latitude: "52.52437000",
    longitude: "13.41053000",
    wikiDataId: "Q64",
  },
  {
    id: 52515,
    name: "Athens",
    state_id: 2122,
    state_code: "I",
    state_name: "Attica Region",
    country_id: 85,
    country_code: "GR",
    country_name: "Greece",
    latitude: "37.98376000",
    longitude: "23.72784000",
    wikiDataId: "Q1524",
  },
  {
    id: 15101,
    name: "São Paulo",
    state_id: 2021,
    state_code: "SP",
    state_name: "São Paulo",
    country_id: 31,
    country_code: "BR",
    country_name: "Brazil",
    latitude: "-23.54750000",
    longitude: "-46.63611000",
    wikiDataId: "Q174",
  },
  {
    id: 76497,
    name: "Kuala Lumpur",
    state_id: 1949,
    state_code: "14",
    state_name: "Kuala Lumpur",
    country_id: 132,
    country_code: "MY",
    country_name: "Malaysia",
    latitude: "3.14120000",
    longitude: "101.68653000",
    wikiDataId: "Q1865",
  },
  {
    id: 54863,
    name: "Rijeka",
    state_id: 735,
    state_code: "08",
    state_name: "Primorje-Gorski Kotar",
    country_id: 55,
    country_code: "HR",
    country_name: "Croatia",
    latitude: "45.32673000",
    longitude: "14.44241000",
    wikiDataId: "Q1269809",
  },
  {
    id: 22970,
    name: "Prague",
    state_id: 4598,
    state_code: "10",
    state_name: "Praha, Hlavní město",
    country_id: 58,
    country_code: "CZ",
    country_name: "Czech Republic",
    latitude: "50.08804000",
    longitude: "14.42076000",
    wikiDataId: "Q1085",
  },
  {
    id: 89425,
    name: "Porto",
    state_id: 2243,
    state_code: "13",
    state_name: "Porto",
    country_id: 177,
    country_code: "PT",
    country_name: "Portugal",
    latitude: "41.14961000",
    longitude: "-8.61099000",
    wikiDataId: "Q33707919",
  },
  {
    id: 153483,
    // Renamed from Central to Hong Kong because all the data  city is under this ID
    name: "Hong Kong",
    state_id: 4889,
    state_code: "HCW",
    state_name: "Central and Western",
    country_id: 98,
    country_code: "HK",
    country_name: "Hong Kong S.A.R.",
    latitude: "22.28100000",
    longitude: "114.15900000",
    wikiDataId: "Q562441",
  },

  {
    id: 27540,
    name: "Munich",
    state_id: 3009,
    state_code: "BY",
    state_name: "Bavaria",
    country_id: 82,
    country_code: "DE",
    country_name: "Germany",
    latitude: "48.13743000",
    longitude: "11.57549000",
    wikiDataId: "Q1726",
  },
  {
    id: 125767,
    name: "Salt Lake City",
    state_id: 1414,
    state_code: "UT",
    state_name: "Utah",
    country_id: 233,
    country_code: "US",
    country_name: "United States",
    latitude: "40.76078000",
    longitude: "-111.89105000",
    wikiDataId: "Q23337",
  },
  {
    id: 21074,
    name: "Medellín",
    state_id: 2890,
    state_code: "ANT",
    state_name: "Antioquia",
    country_id: 48,
    country_code: "CO",
    country_name: "Colombia",
    latitude: "6.25184000",
    longitude: "-75.56359000",
    wikiDataId: "Q48278",
  },
  {
    id: 89693,
    name: "Água de Pena",
    state_id: 2231,
    state_code: "30",
    state_name: "Madeira",
    country_id: 177,
    country_code: "PT",
    country_name: "Portugal",
    latitude: "32.70143000",
    longitude: "-16.77874000",
    wikiDataId: "Q1999047",
  },
  {
    id: 88978,
    name: "Arco da Calheta",
    state_id: 2231,
    state_code: "30",
    state_name: "Madeira",
    country_id: 177,
    country_code: "PT",
    country_name: "Portugal",
    latitude: "32.71502000",
    longitude: "-17.14974000",
    wikiDataId: "Q241974",
  },
  {
    id: 89038,
    name: "Boaventura",
    state_id: 2231,
    state_code: "30",
    state_name: "Madeira",
    country_id: 177,
    country_code: "PT",
    country_name: "Portugal",
    latitude: "32.81846000",
    longitude: "-16.97268000",
    wikiDataId: "Q616959",
  },
  {
    id: 89068,
    name: "Calheta",
    state_id: 2231,
    state_code: "30",
    state_name: "Madeira",
    country_id: 177,
    country_code: "PT",
    country_name: "Portugal",
    latitude: "32.71667000",
    longitude: "-17.18333000",
    wikiDataId: "Q757078",
  },
  {
    id: 89071,
    name: "Camacha",
    state_id: 2231,
    state_code: "30",
    state_name: "Madeira",
    country_id: 177,
    country_code: "PT",
    country_name: "Portugal",
    latitude: "32.67919000",
    longitude: "-16.84462000",
    wikiDataId: "Q34789334",
  },
  {
    id: 89139,
    name: "Câmara de Lobos",
    state_id: 2231,
    state_code: "30",
    state_name: "Madeira",
    country_id: 177,
    country_code: "PT",
    country_name: "Portugal",
    latitude: "32.65043000",
    longitude: "-16.97718000",
    wikiDataId: "Q34799706",
  },
  {
    id: 89074,
    name: "Campanário",
    state_id: 2231,
    state_code: "30",
    state_name: "Madeira",
    country_id: 177,
    country_code: "PT",
    country_name: "Portugal",
    latitude: "32.66578000",
    longitude: "-17.03576000",
    wikiDataId: "Q34789497",
  },
  {
    id: 89084,
    name: "Canhas",
    state_id: 2231,
    state_code: "30",
    state_name: "Madeira",
    country_id: 177,
    country_code: "PT",
    country_name: "Portugal",
    latitude: "32.69465000",
    longitude: "-17.09867000",
    wikiDataId: "Q34789729",
  },
  {
    id: 89086,
    name: "Caniçal",
    state_id: 2231,
    state_code: "30",
    state_name: "Madeira",
    country_id: 177,
    country_code: "PT",
    country_name: "Portugal",
    latitude: "32.73834000",
    longitude: "-16.73836000",
    wikiDataId: "Q34789749",
  },
  {
    id: 89087,
    name: "Caniço",
    state_id: 2231,
    state_code: "30",
    state_name: "Madeira",
    country_id: 177,
    country_code: "PT",
    country_name: "Portugal",
    latitude: "32.65078000",
    longitude: "-16.83749000",
    wikiDataId: "Q34789749",
  },
  {
    id: 89138,
    name: "Curral das Freiras",
    state_id: 2231,
    state_code: "30",
    state_name: "Madeira",
    country_id: 177,
    country_code: "PT",
    country_name: "Portugal",
    latitude: "32.72029000",
    longitude: "-16.96993000",
    wikiDataId: "Q602368",
  },
  {
    id: 89156,
    name: "Estreito da Calheta",
    state_id: 2231,
    state_code: "30",
    state_name: "Madeira",
    country_id: 177,
    country_code: "PT",
    country_name: "Portugal",
    latitude: "32.73704000",
    longitude: "-17.18674000",
    wikiDataId: "Q49349878",
  },
  {
    id: 89161,
    name: "Faial",
    state_id: 2231,
    state_code: "30",
    state_name: "Madeira",
    country_id: 177,
    country_code: "PT",
    country_name: "Portugal",
    latitude: "32.78333000",
    longitude: "-16.85000000",
    wikiDataId: "Q551080",
  },
  {
    id: 89162,
    name: "Fajã da Ovelha",
    state_id: 2231,
    state_code: "30",
    state_name: "Madeira",
    country_id: 177,
    country_code: "PT",
    country_name: "Portugal",
    latitude: "32.77457000",
    longitude: "-17.23412000",
    wikiDataId: "Q551080",
  },
  {
    id: 89197,
    name: "Funchal",
    state_id: 2231,
    state_code: "30",
    state_name: "Madeira",
    country_id: 177,
    country_code: "PT",
    country_name: "Portugal",
    latitude: "32.66568000",
    longitude: "-16.92547000",
    wikiDataId: "Q25444",
  },
  {
    id: 89262,
    name: "Machico",
    state_id: 2231,
    state_code: "30",
    state_name: "Madeira",
    country_id: 177,
    country_code: "PT",
    country_name: "Portugal",
    latitude: "32.71620000",
    longitude: "-16.76758000",
    wikiDataId: "Q35722090",
  },
  {
    id: 89351,
    name: "Nossa Senhora do Monte",
    state_id: 2231,
    state_code: "30",
    state_name: "Madeira",
    country_id: 177,
    country_code: "PT",
    country_name: "Portugal",
    latitude: "32.66667000",
    longitude: "-16.90000000",
    wikiDataId: "Q564762",
  },
  {
    id: 89414,
    name: "Ponta do Sol",
    state_id: 2231,
    state_code: "30",
    state_name: "Madeira",
    country_id: 177,
    country_code: "PT",
    country_name: "Portugal",
    latitude: "32.67980000",
    longitude: "-17.10000000",
    wikiDataId: "Q49361861",
  },
  {
    id: 89432,
    name: "Porto da Cruz",
    state_id: 2231,
    state_code: "30",
    state_name: "Madeira",
    country_id: 177,
    country_code: "PT",
    country_name: "Portugal",
    latitude: "32.76667000",
    longitude: "-16.83333000",
    wikiDataId: "Q932115",
  },
  {
    id: 89429,
    name: "Porto Moniz",
    state_id: 2231,
    state_code: "30",
    state_name: "Madeira",
    country_id: 177,
    country_code: "PT",
    country_name: "Portugal",
    latitude: "32.86681000",
    longitude: "-17.16667000",
    wikiDataId: "Q49362020",
  },
  {
    id: 89431,
    name: "Porto Santo",
    state_id: 2231,
    state_code: "30",
    state_name: "Madeira",
    country_id: 177,
    country_code: "PT",
    country_name: "Portugal",
    latitude: "33.07145000",
    longitude: "-16.34304000",
    wikiDataId: "Q27320",
  },
  {
    id: 89465,
    name: "Ribeira Brava",
    state_id: 2231,
    state_code: "30",
    state_name: "Madeira",
    country_id: 177,
    country_code: "PT",
    country_name: "Portugal",
    latitude: "32.67483000",
    longitude: "-17.06288000",
    wikiDataId: "Q49363709",
  },
  {
    id: 89499,
    name: "Santa Cruz",
    state_id: 2231,
    state_code: "30",
    state_name: "Madeira",
    country_id: 177,
    country_code: "PT",
    country_name: "Portugal",
    latitude: "32.68806000",
    longitude: "-16.79388000",
    wikiDataId: "Q49366997",
  },
  {
    id: 89509,
    name: "Santana",
    state_id: 2231,
    state_code: "30",
    state_name: "Madeira",
    country_id: 177,
    country_code: "PT",
    country_name: "Portugal",
    latitude: "32.80000000",
    longitude: "-16.88333000",
    wikiDataId: "Q49367020",
  },
  {
    id: 89563,
    name: "São Jorge",
    state_id: 2231,
    state_code: "30",
    state_name: "Madeira",
    country_id: 177,
    country_code: "PT",
    country_name: "Portugal",
    latitude: "32.81667000",
    longitude: "-16.90000000",
    wikiDataId: "Q1786429",
  },
  {
    id: 89575,
    name: "São Martinho",
    state_id: 2231,
    state_code: "30",
    state_name: "Madeira",
    country_id: 177,
    country_code: "PT",
    country_name: "Portugal",
    latitude: "32.64480000",
    longitude: "-16.93843000",
    wikiDataId: "Q2002595",
  },
  {
    id: 89585,
    name: "São Roque",
    state_id: 2231,
    state_code: "30",
    state_name: "Madeira",
    country_id: 177,
    country_code: "PT",
    country_name: "Portugal",
    latitude: "32.66667000",
    longitude: "-16.91667000",
    wikiDataId: "Q2116898",
  },
  {
    id: 89591,
    name: "São Vicente",
    state_id: 2231,
    state_code: "30",
    state_name: "Madeira",
    country_id: 177,
    country_code: "PT",
    country_name: "Portugal",
    latitude: "32.79673000",
    longitude: "-17.04323000",
    wikiDataId: "Q304464",
  },
  {
    id: 79735,
    name: "Kathmandu",
    state_id: 2073,
    state_code: "1",
    state_name: "Central Region",
    country_id: 154,
    country_code: "NP",
    country_name: "Nepal",
    latitude: "27.70169000",
    longitude: "85.32060000",
    wikiDataId: "Q3037",
  },
  {
    id: 14309,
    name: "Rio de Janeiro",
    state_id: 1997,
    state_code: "RJ",
    state_name: "Rio de Janeiro",
    country_id: 31,
    country_code: "BR",
    country_name: "Brazil",
    latitude: "-22.92008000",
    longitude: "-43.33069000",
    wikiDataId: "Q1760134",
  },
  {
    id: 31501,
    name: "Cuenca",
    state_id: 2923,
    state_code: "A",
    state_name: "Azuay",
    country_id: 64,
    country_code: "EC",
    country_name: "Ecuador",
    latitude: "-2.90055000",
    longitude: "-79.00453000",
    wikiDataId: "Q1014957",
  },
  {
    id: 999999990,
    name: "San Cristobal de las Casas",
    state_id: 3451,
    state_code: "CHP",
    state_name: "Chiapas",
    country_id: 142,
    country_code: "MX",
    country_name: "Mexico",
    latitude: "16.7370",
    longitude: "-92.6389",
    wikiDataId: "Q2884933",
  },
  {
    id: 132132,
    name: "Hyderabad",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: "17.38405000",
    longitude: "78.45636000",
    wikiDataId: "Q1361",
  },
  {
    id: 64970,
    name: "Phnom Penh",
    state_id: 3994,
    state_code: "12",
    state_name: "Phnom Penh",
    country_id: 37,
    country_code: "KH",
    country_name: "Cambodia",
    latitude: "11.56245000",
    longitude: "104.91601000",
    wikiDataId: "Q1850",
  },
  {
    id: 72432,
    name: "Oaxaca",
    state_id: 3448,
    state_code: "OAX",
    state_name: "Oaxaca",
    country_id: 142,
    country_code: "MX",
    country_name: "Mexico",
    latitude: "17.06542000",
    longitude: "-96.72365000",
    wikiDataId: "Q131429",
  },
  {
    id: 88480,
    name: "Warsaw",
    state_id: 1637,
    state_code: "MZ",
    state_name: "Masovian Voivodeship",
    country_id: 176,
    country_code: "PL",
    country_name: "Poland",
    latitude: "52.22977000",
    longitude: "21.01178000",
    wikiDataId: "Q270",
  },
  {
    id: 55002,
    name: "Zadar",
    state_id: 727,
    state_code: "13",
    state_name: "Zadar",
    country_id: 55,
    country_code: "HR",
    country_name: "Croatia",
    latitude: "44.11972000",
    longitude: "15.24222000",
    wikiDataId: "Q3370",
  },
  {
    id: 79915,
    name: "Wellington",
    state_id: 4065,
    state_code: "WGN",
    state_name: "Wellington Region",
    country_id: 158,
    country_code: "NZ",
    country_name: "New Zealand",
    latitude: "-41.28664000",
    longitude: "174.77557000",
    wikiDataId: "Q23661",
  },
  {
    id: 37816,
    name: "Valencia",
    state_id: 1175,
    state_code: "V",
    state_name: "Valencia",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    latitude: "39.46975000",
    longitude: "-0.37739000",
    wikiDataId: "Q8818",
  },
  {
    id: 38770,
    name: "Helsinki",
    state_id: 1510,
    state_code: "18",
    state_name: "Uusimaa",
    country_id: 74,
    country_code: "FI",
    country_name: "Finland",
    latitude: "60.16952000",
    longitude: "24.93545000",
    wikiDataId: "Q1757",
  },
  {
    id: 76455,
    name: "Johor Bahru",
    state_id: 1950,
    state_code: "01",
    state_name: "Johor",
    country_id: 132,
    country_code: "MY",
    country_name: "Malaysia",
    latitude: "1.46550000",
    longitude: "103.75780000",
    wikiDataId: "Q231318",
  },
  {
    id: 21867,
    name: "Havana",
    state_id: 272,
    state_code: "03",
    state_name: "Havana Province",
    country_id: 56,
    country_code: "CU",
    country_name: "Cuba",
    latitude: "23.13302000",
    longitude: "-82.38304000",
    wikiDataId: "Q1563",
  },
  {
    id: 581,
    name: "Yerevan",
    state_id: 2030,
    state_code: "ER",
    state_name: "Yerevan",
    country_id: 12,
    country_code: "AM",
    country_name: "Armenia",
    latitude: "40.18111000",
    longitude: "44.51361000",
    wikiDataId: "Q2222448",
  },
  {
    id: 50707,
    name: "Newcastle",
    state_id: 2337,
    state_code: "NIR",
    state_name: "Northern Ireland",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    latitude: "54.21804000",
    longitude: "-5.88979000",
    wikiDataId: "Q1973774",
  },
  {
    id: 67158,
    name: "Marrakech",
    state_id: 3311,
    state_code: "SAF",
    state_name: "Safi",
    country_id: 149,
    country_code: "MA",
    country_name: "Morocco",
    latitude: "31.63623000",
    longitude: "-8.01041000",
    wikiDataId: "Q6726176",
  },
  {
    id: 9701,
    name: "Sofia",
    state_id: 4705,
    state_code: "22",
    state_name: "Sofia City Province",
    country_id: 34,
    country_code: "BG",
    country_name: "Bulgaria",
    latitude: "42.69751000",
    longitude: "23.32415000",
    wikiDataId: "Q472",
  },
  {
    id: 56966,
    name: "Senggigi",
    state_id: 1814,
    state_code: "NB",
    state_name: "Nusa Tenggara Barat",
    country_id: 102,
    country_code: "ID",
    country_name: "Indonesia",
    latitude: "-8.49190000",
    longitude: "116.04240000",
    wikiDataId: "Q1869598",
  },
  {
    id: 111590,
    name: "Atlanta",
    state_id: 1455,
    state_code: "GA",
    state_name: "Georgia",
    country_id: 233,
    country_code: "US",
    country_name: "United States",
    latitude: "33.74900000",
    longitude: "-84.38798000",
    wikiDataId: "Q23556",
  },
  {
    id: 109030,
    name: "Tainan",
    state_id: 3421,
    state_code: "TNN",
    state_name: "Tainan",
    country_id: 216,
    country_code: "TW",
    country_name: "Taiwan",
    latitude: "22.99083000",
    longitude: "120.21333000",
    wikiDataId: "Q140631",
  },
  {
    id: 57046,
    name: "Ubud",
    state_id: 1826,
    state_code: "BA",
    state_name: "Bali",
    country_id: 102,
    country_code: "ID",
    country_name: "Indonesia",
    latitude: "-8.50980000",
    longitude: "115.26540000",
    wikiDataId: "Q210654",
  },
  {
    id: 37239,
    name: "Seville",
    state_id: 1193,
    state_code: "SE",
    state_name: "Sevilla",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    latitude: "37.38283000",
    longitude: "-5.97317000",
    wikiDataId: "Q55893348",
  },
  {
    id: 142544,
    name: "Guadalajara",
    state_id: 4857,
    state_code: "JAL",
    state_name: "Jalisco",
    country_id: 142,
    country_code: "MX",
    country_name: "Mexico",
    latitude: "20.67666667",
    longitude: "-103.34750000",
    wikiDataId: "Q9022",
  },
  {
    id: 75804,
    name: "Tulum",
    state_id: 3467,
    state_code: "ROO",
    state_name: "Quintana Roo",
    country_id: 142,
    country_code: "MX",
    country_name: "Mexico",
    latitude: "20.22618000",
    longitude: "-87.51702000",
    wikiDataId: "Q1709171",
  },
  {
    id: 53278,
    name: "Thessaloníki",
    state_id: 2125,
    state_code: "B",
    state_name: "Central Macedonia",
    country_id: 85,
    country_code: "GR",
    country_name: "Greece",
    latitude: "40.64361000",
    longitude: "22.93086000",
    wikiDataId: "Q17151",
  },
  {
    id: 65607,
    name: "Almaty",
    state_id: 152,
    state_code: "ALA",
    state_name: "Almaty",
    country_id: 112,
    country_code: "KZ",
    country_name: "Kazakhstan",
    latitude: "43.25667000",
    longitude: "76.92861000",
    wikiDataId: "Q35493",
  },
  // {
  //   id: 106650,
  //   name: 'Phuket',
  //   state_id: 3536,
  //   state_code: '83',
  //   state_name: 'Phuket',
  //   country_id: 219,
  //   country_code: 'TH',
  //   country_name: 'Thailand',
  //   latitude: '7.89059000',
  //   longitude: '98.39810000',
  //   wikiDataId: 'Q844430',
  // },
  {
    id: 133024,
    name: "Mumbai",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: "19.07283000",
    longitude: "72.88261000",
    wikiDataId: "Q1156",
  },

  {
    id: 19387,
    name: "Chengdu",
    state_id: 2277,
    state_code: "SC",
    state_name: "Sichuan",
    country_id: 45,
    country_code: "CN",
    country_name: "China",
    latitude: "30.66667000",
    longitude: "104.06667000",
    wikiDataId: "Q30002",
  },
  {
    id: 31551,
    name: "Puerto Ayora",
    state_id: 2905,
    state_code: "W",
    state_name: "Galápagos",
    country_id: 64,
    country_code: "EC",
    country_name: "Ecuador",
    latitude: "-0.74018000",
    longitude: "-90.31380000",
    wikiDataId: "Q1013526",
  },
  {
    id: 31552,
    name: "Puerto Baquerizo Moreno",
    state_id: 2905,
    state_code: "W",
    state_name: "Galápagos",
    country_id: 64,
    country_code: "EC",
    country_name: "Ecuador",
    latitude: "-0.90172000",
    longitude: "-89.61021000",
    wikiDataId: "Q694109",
  },
  {
    id: 31555,
    name: "Puerto Villamil",
    state_id: 2905,
    state_code: "W",
    state_name: "Galápagos",
    country_id: 64,
    country_code: "EC",
    country_name: "Ecuador",
    latitude: "-0.95542000",
    longitude: "-90.96654000",
    wikiDataId: "Q1208152",
  },
  {
    id: 122795,
    name: "New York City",
    state_id: 1452,
    state_code: "NY",
    state_name: "New York",
    country_id: 233,
    country_code: "US",
    country_name: "United States",
    latitude: "40.71427000",
    longitude: "-74.00597000",
    wikiDataId: "Q60",
  },
  {
    id: 97170,
    name: "Belgrade",
    state_id: 3716,
    state_code: "02",
    state_name: "Central Banat District",
    country_id: 196,
    country_code: "RS",
    country_name: "Serbia",
    latitude: "44.80401000",
    longitude: "20.46513000",
    wikiDataId: "Q3711",
  },
  {
    id: 77340,
    name: "Amsterdam",
    state_id: 2612,
    state_code: "NH",
    state_name: "North Holland",
    country_id: 156,
    country_code: "NL",
    country_name: "Netherlands",
    latitude: "52.37403000",
    longitude: "4.88969000",
    wikiDataId: "Q727",
  },
  {
    id: 78822,
    name: "Utrecht",
    state_id: 2610,
    state_code: "UT",
    state_name: "Utrecht",
    country_id: 156,
    country_code: "NL",
    country_name: "Netherlands",
    latitude: "52.09083000",
    longitude: "5.12222000",
    wikiDataId: "Q39297398",
  },
  {
    id: 125468,
    name: "Rotterdam",
    state_id: 1452,
    state_code: "NY",
    state_name: "New York",
    country_id: 233,
    country_code: "US",
    country_name: "United States",
    latitude: "42.78702000",
    longitude: "-73.97096000",
    wikiDataId: "Q2166239",
  },
  {
    id: 78779,
    name: "The Hague",
    state_id: 2614,
    state_code: "ZH",
    state_name: "South Holland",
    country_id: 156,
    country_code: "NL",
    country_name: "Netherlands",
    latitude: "52.07667000",
    longitude: "4.29861000",
    wikiDataId: "Q41795540",
  },
  {
    id: 31985,
    name: "Ajalvir",
    state_id: 1158,
    state_code: "M",
    state_name: "Madrid",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    latitude: "40.53205000",
    longitude: "-3.47841000",
    wikiDataId: "Q855674",
  },
  {
    id: 77800,
    name: "Groningen",
    state_id: 2617,
    state_code: "GR",
    state_name: "Groningen",
    country_id: 156,
    country_code: "NL",
    country_name: "Netherlands",
    latitude: "53.22369000",
    longitude: "6.56479000",
    wikiDataId: "Q892526",
  },
  {
    id: 90347,
    name: "Bucharest",
    state_id: 4730,
    state_code: "B",
    state_name: "Bucharest",
    country_id: 181,
    country_code: "RO",
    country_name: "Romania",
    latitude: "44.43225000",
    longitude: "26.10626000",
    wikiDataId: "Q19660",
  },
  {
    id: 83524,
    name: "Manila",
    state_id: 1347,
    state_code: "NCR",
    state_name: "Metro Manila",
    country_id: 174,
    country_code: "PH",
    country_name: "Philippines",
    latitude: "14.60420000",
    longitude: "120.98220000",
    wikiDataId: "Q1461",
  },

  {
    id: 32,
    name: "Dubai",
    state_id: 3391,
    state_code: "DU",
    state_name: "Dubai",
    country_id: 231,
    country_code: "AE",
    country_name: "United Arab Emirates",
    latitude: "25.06570000",
    longitude: "55.17128000",
    wikiDataId: "Q6742405",
  },

  {
    id: 112589,
    name: "Boston",
    state_id: 1433,
    state_code: "MA",
    state_name: "Massachusetts",
    country_id: 233,
    country_code: "US",
    country_name: "United States",
    latitude: "42.35843000",
    longitude: "-71.05977000",
    wikiDataId: "Q100",
  },
  {
    id: 25866,
    name: "Hamburg",
    state_id: 3016,
    state_code: "HH",
    state_name: "Hamburg",
    country_id: 82,
    country_code: "DE",
    country_name: "Germany",
    latitude: "53.57532000",
    longitude: "10.01534000",
    wikiDataId: "Q1055",
  },
  {
    id: 22055,
    name: "Limassol",
    state_id: 748,
    state_code: "02",
    state_name: "Limassol District (Leymasun)",
    country_id: 57,
    country_code: "CY",
    country_name: "Cyprus",
    latitude: "34.68406000",
    longitude: "33.03794000",
    wikiDataId: "Q185632",
  },
  {
    id: 17145,
    name: "Vancouver",
    state_id: 875,
    state_code: "BC",
    state_name: "British Columbia",
    country_id: 39,
    country_code: "CA",
    country_name: "Canada",
    latitude: "49.24966000",
    longitude: "-123.11934000",
    wikiDataId: "Q24639",
  },
  {
    id: 37036,
    name: "Santa Cruz de Tenerife",
    state_id: 5112,
    state_code: "TF",
    state_name: "Santa Cruz de Tenerife",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    latitude: "28.46824000",
    longitude: "-16.25462000",
    wikiDataId: "Q14328",
  },
  {
    id: 153544,
    name: "Arrecife",
    state_id: 5222,
    state_code: "CN",
    state_name: "Canarias",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    latitude: "28.96300000",
    longitude: "13.54770000",
    wikiDataId: "Q27547",
  },
  {
    id: 153543,
    name: "Las Palmas de Gran Canaria",
    state_id: 5222,
    state_code: "CN",
    state_name: "Canarias",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    latitude: "28.12360000",
    longitude: "-15.43670000",
    wikiDataId: "Q178948",
  },
  {
    id: 153545,
    name: "Puerto del Rosario",
    state_id: 5222,
    state_code: "CN",
    state_name: "Canarias",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    latitude: "28.50000000",
    longitude: "-13.86270000",
    wikiDataId: "Q27604",
  },
  {
    id: 153547,
    name: "San Sebastián de La Gomera",
    state_id: 5222,
    state_code: "CN",
    state_name: "Canarias",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    latitude: "28.09380000",
    longitude: "17.11140000",
    wikiDataId: "Q46958",
  },
  {
    id: 153546,
    name: "Santa Cruz de La Palma",
    state_id: 5222,
    state_code: "CN",
    state_name: "Canarias",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    latitude: "28.68260000",
    longitude: "17.76460000",
    wikiDataId: "Q45686",
  },
  {
    id: 153542,
    name: "Santa Cruz de Tenerife",
    state_id: 5222,
    state_code: "CN",
    state_name: "Canarias",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    latitude: "28.46360000",
    longitude: "16.25180000",
    wikiDataId: "Q45688",
  },
  {
    id: 153548,
    name: "Valverde",
    state_id: 5222,
    state_code: "CN",
    state_name: "Canarias",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    latitude: "27.80860000",
    longitude: "17.88750000",
    wikiDataId: "Q46778",
  },
  {
    id: 31931,
    name: "Adeje",
    state_id: 5112,
    state_code: "TF",
    state_name: "Santa Cruz de Tenerife",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    latitude: "28.12271000",
    longitude: "-16.72600000",
    wikiDataId: "Q1404668",
  },
  {
    id: 31969,
    name: "Agulo",
    state_id: 5112,
    state_code: "TF",
    state_name: "Santa Cruz de Tenerife",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    latitude: "28.18778000",
    longitude: "-17.19678000",
    wikiDataId: "Q24016521",
  },
  {
    id: 31993,
    name: "Alajeró",
    state_id: 5112,
    state_code: "TF",
    state_name: "Santa Cruz de Tenerife",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    latitude: "28.06205000",
    longitude: "-17.24073000",
    wikiDataId: "Q932914",
  },
  {
    id: 32400,
    name: "Arafo",
    state_id: 5112,
    state_code: "TF",
    state_name: "Santa Cruz de Tenerife",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    latitude: "28.33971000",
    longitude: "-16.42244000",
    wikiDataId: "Q624967",
  },
  {
    id: 152448,
    name: "Arico",
    state_id: 5112,
    state_code: "TF",
    state_name: "Santa Cruz de Tenerife",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    latitude: "0.00000000",
    longitude: "0.00000000",
    wikiDataId: null,
  },
  {
    id: 32497,
    name: "Arona",
    state_id: 5112,
    state_code: "TF",
    state_name: "Santa Cruz de Tenerife",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    latitude: "28.09962000",
    longitude: "-16.68102000",
    wikiDataId: "Q5705046",
  },
  {
    id: 32666,
    name: "Barlovento",
    state_id: 5112,
    state_code: "TF",
    state_name: "Santa Cruz de Tenerife",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    latitude: "28.82708000",
    longitude: "-17.80377000",
    wikiDataId: "Q1645760",
  },
  {
    id: 32980,
    name: "Breña Alta",
    state_id: 5112,
    state_code: "TF",
    state_name: "Santa Cruz de Tenerife",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    latitude: "28.65000000",
    longitude: "-17.78333000",
    wikiDataId: "Q433958",
  },
  {
    id: 32981,
    name: "Breña Baja",
    state_id: 5112,
    state_code: "TF",
    state_name: "Santa Cruz de Tenerife",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    latitude: "28.63011000",
    longitude: "-17.78953000",
    wikiDataId: "Q433986",
  },
  {
    id: 33005,
    name: "Buenavista del Norte",
    state_id: 5112,
    state_code: "TF",
    state_name: "Santa Cruz de Tenerife",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    latitude: "28.37458000",
    longitude: "-16.86098000",
    wikiDataId: "Q594961",
  },
  {
    id: 33226,
    name: "Candelaria",
    state_id: 5112,
    state_code: "TF",
    state_name: "Santa Cruz de Tenerife",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    latitude: "28.35480000",
    longitude: "-16.37268000",
    wikiDataId: "Q487268",
  },
  {
    id: 34167,
    name: "Fasnia",
    state_id: 5112,
    state_code: "TF",
    state_name: "Santa Cruz de Tenerife",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    latitude: "28.23638000",
    longitude: "-16.43886000",
    wikiDataId: "Q524431",
  },
  {
    id: 34268,
    name: "Frontera",
    state_id: 5112,
    state_code: "TF",
    state_name: "Santa Cruz de Tenerife",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    latitude: "27.75404000",
    longitude: "-18.00367000",
    wikiDataId: "Q1798827",
  },
  {
    id: 34276,
    name: "Fuencaliente de la Palma",
    state_id: 5112,
    state_code: "TF",
    state_name: "Santa Cruz de Tenerife",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    latitude: "28.49236000",
    longitude: "-17.84529000",
    wikiDataId: "Q434019",
  },
  {
    id: 34405,
    name: "Garachico",
    state_id: 5112,
    state_code: "TF",
    state_name: "Santa Cruz de Tenerife",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    latitude: "28.37365000",
    longitude: "-16.76342000",
    wikiDataId: "Q612815",
  },
  {
    id: 34406,
    name: "Garafía",
    state_id: 5112,
    state_code: "TF",
    state_name: "Santa Cruz de Tenerife",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    latitude: "28.81667000",
    longitude: "-17.93333000",
    wikiDataId: "Q434053",
  },
  {
    id: 34498,
    name: "Granadilla de Abona",
    state_id: 5112,
    state_code: "TF",
    state_name: "Santa Cruz de Tenerife",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    latitude: "28.11882000",
    longitude: "-16.57599000",
    wikiDataId: "Q943431",
  },
  {
    id: 152449,
    name: "Guancha La",
    state_id: 5112,
    state_code: "TF",
    state_name: "Santa Cruz de Tenerife",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    latitude: "0.00000000",
    longitude: "0.00000000",
    wikiDataId: null,
  },
  {
    id: 34557,
    name: "Guía de Isora",
    state_id: 5112,
    state_code: "TF",
    state_name: "Santa Cruz de Tenerife",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    latitude: "28.21154000",
    longitude: "-16.77947000",
    wikiDataId: "Q765879",
  },
  {
    id: 34569,
    name: "Güimar",
    state_id: 5112,
    state_code: "TF",
    state_name: "Santa Cruz de Tenerife",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    latitude: "28.31122000",
    longitude: "-16.41276000",
    wikiDataId: "Q983433",
  },
  {
    id: 34586,
    name: "Hermigua",
    state_id: 5112,
    state_code: "TF",
    state_name: "Santa Cruz de Tenerife",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    latitude: "28.16766000",
    longitude: "-17.19051000",
    wikiDataId: "Q1651782",
  },
  {
    id: 34732,
    name: "Icod de los Vinos",
    state_id: 5112,
    state_code: "TF",
    state_name: "Santa Cruz de Tenerife",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    latitude: "28.37241000",
    longitude: "-16.71188000",
    wikiDataId: "Q23984815",
  },
  {
    id: 152451,
    name: "Llanos de Aridane Los",
    state_id: 5112,
    state_code: "TF",
    state_name: "Santa Cruz de Tenerife",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    latitude: "0.00000000",
    longitude: "0.00000000",
    wikiDataId: null,
  },
  {
    id: 152452,
    name: "Matanza de Acentejo La",
    state_id: 5112,
    state_code: "TF",
    state_name: "Santa Cruz de Tenerife",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    latitude: "0.00000000",
    longitude: "0.00000000",
    wikiDataId: null,
  },
  {
    id: 152453,
    name: "Orotava La",
    state_id: 5112,
    state_code: "TF",
    state_name: "Santa Cruz de Tenerife",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    latitude: "0.00000000",
    longitude: "0.00000000",
    wikiDataId: null,
  },
  {
    id: 152454,
    name: "Paso El",
    state_id: 5112,
    state_code: "TF",
    state_name: "Santa Cruz de Tenerife",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    latitude: "0.00000000",
    longitude: "0.00000000",
    wikiDataId: null,
  },
  {
    id: 152465,
    name: "Pinar de El Hierro El",
    state_id: 5112,
    state_code: "TF",
    state_name: "Santa Cruz de Tenerife",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    latitude: "0.00000000",
    longitude: "0.00000000",
    wikiDataId: null,
  },
  {
    id: 36454,
    name: "Puerto de la Cruz",
    state_id: 5112,
    state_code: "TF",
    state_name: "Santa Cruz de Tenerife",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    latitude: "28.41397000",
    longitude: "-16.54867000",
    wikiDataId: "Q623549",
  },
  {
    id: 36472,
    name: "Puntagorda",
    state_id: 5112,
    state_code: "TF",
    state_name: "Santa Cruz de Tenerife",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    latitude: "28.77490000",
    longitude: "-17.97741000",
    wikiDataId: "Q2118556",
  },
  {
    id: 48168,
    name: "Aberdeen",
    state_id: 2335,
    state_code: "SCT",
    state_name: "Scotland",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    latitude: "57.14369000",
    longitude: "-2.09814000",
    wikiDataId: "Q36405",
  },
  {
    id: 31803,
    name: "Dahab",
    state_id: 3237,
    state_code: "JS",
    state_name: "South Sinai",
    country_id: 65,
    country_code: "EG",
    country_name: "Egypt",
    latitude: "28.48208000",
    longitude: "34.49505000",
    wikiDataId: "Q370495",
  },
  {
    id: 11843,
    name: "Florianópolis",
    state_id: 2014,
    state_code: "SC",
    state_name: "Santa Catarina",
    country_id: 31,
    country_code: "BR",
    country_name: "Brazil",
    latitude: "-27.59667000",
    longitude: "-48.54917000",
    wikiDataId: "Q132997",
  },
  {
    id: 131059,
    name: "Cape Town",
    state_id: 939,
    state_code: "WC",
    state_name: "Western Cape",
    country_id: 204,
    country_code: "ZA",
    country_name: "South Africa",
    latitude: "-33.92584000",
    longitude: "18.42322000",
    wikiDataId: "Q5465",
  },
  {
    id: 131071,
    name: "City of Johannesburg Metropolitan Municipality",
    state_id: 936,
    state_code: "GP",
    state_name: "Gauteng",
    country_id: 204,
    country_code: "ZA",
    country_name: "South Africa",
    latitude: "-26.17673000",
    longitude: "27.96353000",
    wikiDataId: "Q2346838",
  },
  {
    id: 72814,
    name: "Playa del Carmen",
    state_id: 3467,
    state_code: "ROO",
    state_name: "Quintana Roo",
    country_id: 142,
    country_code: "MX",
    country_name: "Mexico",
    latitude: "20.62740000",
    longitude: "-87.07987000",
    wikiDataId: "Q989988",
  },
  {
    id: 52530,
    name: "Chaniá",
    state_id: 2109,
    state_code: "M",
    state_name: "Crete Region",
    country_id: 85,
    country_code: "GR",
    country_name: "Greece",
    latitude: "35.51124000",
    longitude: "24.02921000",
    wikiDataId: "Q182299",
  },
  {
    id: 122756,
    name: "New Orleans",
    state_id: 1457,
    state_code: "LA",
    state_name: "Louisiana",
    country_id: 233,
    country_code: "US",
    country_name: "United States",
    latitude: "29.95465000",
    longitude: "-90.07507000",
    wikiDataId: "Q34404",
  },
  {
    id: 44298,
    name: "Montpellier",
    state_id: 4799,
    state_code: "OCC",
    state_name: "Occitanie",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    latitude: "43.61092000",
    longitude: "3.87723000",
    wikiDataId: "Q6441",
  },
  {
    id: 43485,
    name: "Lille",
    state_id: 4828,
    state_code: "HDF",
    state_name: "Hauts-de-France",
    country_id: 75,
    country_code: "FR",
    country_name: "France",
    latitude: "50.63297000",
    longitude: "3.05858000",
    wikiDataId: "Q648",
  },
  {
    id: 34497,
    name: "Granada",
    state_id: 5098,
    state_code: "GR",
    state_name: "Granada",
    country_id: 207,
    country_code: "ES",
    country_name: "Spain",
    latitude: "37.18817000",
    longitude: "-3.60667000",
    wikiDataId: "Q31916723",
  },
  {
    id: 113931,
    name: "Chicago",
    state_id: 1425,
    state_code: "IL",
    state_name: "Illinois",
    country_id: 233,
    country_code: "US",
    country_name: "United States",
    latitude: "41.85003000",
    longitude: "-87.65005000",
    wikiDataId: "Q1297",
  },
  {
    id: 20063,
    name: "Shanghai",
    state_id: 2249,
    state_code: "SH",
    state_name: "Shanghai",
    country_id: 45,
    country_code: "CN",
    country_name: "China",
    latitude: "31.22222000",
    longitude: "121.45806000",
    wikiDataId: "Q8686",
  },
  {
    id: 78285,
    name: "Leiden",
    state_id: 2614,
    state_code: "ZH",
    state_name: "South Holland",
    country_id: 156,
    country_code: "NL",
    country_name: "Netherlands",
    latitude: "52.15833000",
    longitude: "4.49306000",
    wikiDataId: "Q939346",
  },
  {
    id: 104374,
    name: "Bratislava",
    state_id: 4356,
    state_code: "BL",
    state_name: "Bratislava Region",
    country_id: 200,
    country_code: "SK",
    country_name: "Slovakia",
    latitude: "48.14816000",
    longitude: "17.10674000",
    wikiDataId: "Q1780",
  },
  {
    id: 52053,
    name: "Batumi",
    state_id: 900,
    state_code: "AJ",
    state_name: "Adjara",
    country_id: 81,
    country_code: "GE",
    country_name: "Georgia",
    latitude: "41.64228000",
    longitude: "41.63392000",
    wikiDataId: "Q25475",
  },
  {
    id: 50388,
    name: "London",
    state_id: 2336,
    state_code: "ENG",
    state_name: "England",
    country_id: 232,
    country_code: "GB",
    country_name: "United Kingdom",
    latitude: "51.50853000",
    longitude: "-0.12574000",
    wikiDataId: "Q84",
  },
  {
    id: 136468,
    name: "Cagliari",
    state_id: 1715,
    state_code: "88",
    state_name: "Sardinia",
    country_id: 107,
    country_code: "IT",
    country_name: "Italy",
    latitude: "39.23054000",
    longitude: "9.11917000",
    wikiDataId: "Q3649521",
  },
  {
    id: 118924,
    name: "Indianapolis",
    state_id: 1440,
    state_code: "IN",
    state_name: "Indiana",
    country_id: 233,
    country_code: "US",
    country_name: "United States",
    latitude: "39.76838000",
    longitude: "-86.15804000",
    wikiDataId: "Q6346",
  },
  {
    id: 29444,
    name: "Stuttgart",
    state_id: 3006,
    state_code: "BW",
    state_name: "Baden-Württemberg",
    country_id: 82,
    country_code: "DE",
    country_name: "Germany",
    latitude: "48.78232000",
    longitude: "9.17702000",
    wikiDataId: "Q1022",
  },
  {
    id: 79866,
    name: "Queenstown",
    state_id: 4062,
    state_code: "OTA",
    state_name: "Otago Region",
    country_id: 158,
    country_code: "NZ",
    country_name: "New Zealand",
    latitude: "-45.03023000",
    longitude: "168.66271000",
    wikiDataId: "Q613602",
  },
  {
    id: 3681,
    name: "Vienna",
    state_id: 2060,
    state_code: "9",
    state_name: "Vienna",
    country_id: 15,
    country_code: "AT",
    country_name: "Austria",
    latitude: "48.20849000",
    longitude: "16.37208000",
    wikiDataId: "Q1741",
  },
]
  .filter(
    (city) =>
      ![
        "AE", // United Arab Emirates
        "GB", // United Kingdom
        "MK", // North Macedonia
        "NL", // Netherlands
        "US", // United States
        "RS", // Serbia
        "SK", // Slovakia
        "TW", // Taiwan
        "ES", // Spain
        "KR", // South Korea
        "TR", // Turkey
        "ZA", // South Africa
      ].includes(city.country_code)
  )
  .sort((a, b) => a.name.localeCompare(b.name))
  .filter(
    (city, index, self) =>
      !(
        city.country_code === "PT" && ![89424, 143381, 143446].includes(city.id)
      )
  );
